<template>
  <v-container fluid>
      <v-row>
          <v-col>
            <v-dialog
            v-model="dialog"
            width="700"
            
            transition="dialog-bottom-transition"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs" v-on="on" @click="getUnitesReference();getCategoriesReference()"><v-icon>mdi-plus</v-icon><v-icon>mdi-pus</v-icon>زیادکرنی کاڵا</v-btn>
            </template>
            <v-card>
                <v-toolbar
                dark
                outlined
                :class="isUpdate?'orange accent-2':'blue accent-2'"
                elevation="1"
                >
                <v-btn
                    icon
                    
                    @click="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn  text @click="isUpdate? update():create()" >
                    <v-icon>{{isUpdate? 'mdi-check':'mdi-plus'}}</v-icon> {{isUpdate? 'نوێکردنەوەی کاڵا': 'زیادکردنی کاڵا'}} </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <v-form ref="item">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.stringRule" dense label="ناوی کاڵا" outlined v-model="itemm.item_name"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.stringRule" dense label="کۆدی کاڵا" outlined v-model="itemm.item_code"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.numberRule" prepend-inner-icon="mdi-currency-usd"  dense label="نرخی تێچوو" outlined type="number" v-model="itemm.cost_price"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.numberRule" prepend-inner-icon="mdi-currency-usd" dense label="نرخی فرۆشتن" outlined type="number" v-model="itemm.item_price"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.numberRule" prepend-inner-icon="mdi-currency-usd" dense label="نرخی جوملە" outlined type="number" v-model="itemm.item_price_wholesale"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="12">
                               <v-autocomplete :items="unitReference" item-text="unit_name" item-value="unit_id" dense outlined label="یەکە" v-model="itemm.unit_id"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.numberRule" dense label="کاڵاکان لە یەکەیەکدا" outlined type="number" v-model="itemm.item_per_unit"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="12">
                               <v-autocomplete :items="categoryReference" item-text="category_name" item-value="category_id" dense outlined label="جۆر" v-model="itemm.category_id"></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
            </v-dialog>
            <v-card>
                <v-data-table :headers="headers" :items="items" hide-default-footer  :items-per-page="20"  :loading="loading">
                    <template v-slot:top>
                        <v-text-field @focus="$event.target.select()"
                        v-model="table.search"
                        label="گەڕان"
                        class="mx-4"
                        ></v-text-field>
                    </template>
                    <template v-slot:[`item.cost_price`]="{ item }">
                        {{'$'+item.cost_price.toLocaleString()}}
                    </template>
                    <template v-slot:[`item.item_price`]="{ item }">
                        {{'$'+item.item_price.toLocaleString()}}
                    </template>
                    <template v-slot:[`item.item_price_wholesale`]="{ item }">
                        {{'$'+item.item_price_wholesale.toLocaleString()}}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon" >
                        <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon" @click="itemm = removeProperty(item, ['category_name', 'unit_name']);getUnitesReference();getCategoriesReference(); dialog=true;">mdi-pencil</v-icon>
                        <v-icon large class="ml-2" v-else>mdi-pencil</v-icon>
                        </v-hover>
                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon" >
                            <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon" @click="remove(item.item_id)">mdi-delete</v-icon>
                            <v-icon large class="ml-2" v-else >mdi-delete</v-icon>
                        </v-hover>
                    </template>
                </v-data-table>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfItems / 20)"  v-model="table.page"></v-pagination>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
          </v-col>
      </v-row>
      <notification/>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
            table: {
                page: 1,
                numberOfItems: 0,
                search: ''
            },
            dialog: false,
            unitReference: [],
            categoryReference: [],
            itemm: {
                item_id: 0,
                item_name: '',
                item_code: '',
                category_id: 0,
                cost_price: 0,
                item_price: 0,
                item_price_wholesale: 0,
                unit_id: 0,
                item_per_unit: 0
            },
            loading: false,
            items: [],
            headers: [
                {text: 'ناوی کاڵا', value: 'item_name', align: 'center'},
                {text: 'کۆدی کاڵا', value: 'item_code', align: 'center'},
                {text: 'جۆر', value: 'category_name', align: 'center'},
                {text: ' یەکە', value: 'unit_name', align: 'center'},
                {text: 'دانە لە یەکەدا', value: 'item_per_unit', align: 'center'},
                {text: 'نرخی تێچوو', value: 'cost_price', align: 'center'},
                {text: 'نرخی فرۆشتن', value: 'item_price', align: 'center'},
                {text: 'نرخی جوملە', value: 'item_price_wholesale', align: 'center'},
                {text: 'کردارەکان', value: 'actions', align: 'center'},
            ],
            rules: {
                stringRule: [
                    (v) => {
                        return ( v != '' || 'تکایە خانەکە پڕبکەرەوە')
                    }
                ],
                numberRule: [
                    (v) => {
                        return ( v >= 0 || 'negative number invalid')
                    }
                ]
            }
        }
    },
    methods: {
        async getItems(){
            try {
                this.loading = true
                const {data} = await this.axios.get('/item', {params:{page:this.table.page, search: this.table.search}})
                this.items = data.items
                this.table.numberOfItems = data.count
                this.loading = false
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'هەڵەیەک ڕوویدا!'})
            }
        },
        async getUnitesReference(){
            try {
                const {data} = await this.axios.get('/unit/forReference')
                this.unitReference = data
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'هەڵەیەک ڕوویدا!'})
            }
        },
        async getCategoriesReference(){
            try {
                const {data} = await this.axios.get('/item_category/forReference')
                this.categoryReference = data
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'هەڵەیەک ڕوویدا!'})
            }
        },
        async create(){
            if (this.$refs.item.validate()) {
                try {
                    await this.axios.post('/item', this.itemm)
                    this.reset()
                    this.getItems()
                    this.emitter.$emit('notify', {type: 'success', text:'کاڵاکە زیادکرا'})
                } catch (error) {
                    console.log(error);
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', {type: 'error', text:`${error.response.data.field} is duplicate`})
                    }
                    this.emitter.$emit('notify', {type: 'error', text:'هەڵەیەک ڕوویدا!'})
                }
            }
        },
        async update() {
            if (this.$refs.item.validate()) {
                try {
                    await this.axios.patch('/item/'+ this.itemm.item_id, this.itemm)
                    this.getItems()
                    this.reset()
                    this.dialog = false
                    this.emitter.$emit('notify', {type: 'success', text:'کاڵاکە نوێکرایەوە'})
                } catch (error) {
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', {type: 'error', text:`${error.response.data.field} is duplicate`})
                    }
                    this.emitter.$emit('notify', {type: 'error', text:'هەڵەیەک ڕوویدا!'})
                }
            }
        },
        remove(id){
            this.$confirm('ئایا دڵنیایت لە سڕینەوەی ئەم کاڵایە؟', 'دڵنیابوونەوە', 'question').then(async () => {
                try {
                    await this.axios.delete('/item/'+id)
                    this.getItems()
                    this.emitter.$emit('notify', {type: 'success', text:'کاڵاکە سڕدرایەوە'})
                } catch (error) {
                    this.emitter.$emit('notify', {type: 'error', text:'هەڵەیەک ڕوویدا!'})
                }
            })
        },
        reset(){
            this.itemm = {
                item_id: 0,
                item_name: '',
                item_code: '',
                category_id: 0,
                cost_price: 0,
                item_price: 0,
                item_price_wholesale: 0,
                unit_id: 0,
                item_per_unit: 0
            }
            this.$refs.item.resetValidation();
        },
        removeProperty({...obj} = {}, fields){
            for (let i = 0; i < fields.length; i++)  delete obj[fields[i]]
            return obj
        }
    },
    created() {
        this.getItems()
    },
    watch: {
        dialog(val){
            if (!val) {
                this.unitReference = []
                this.categoryReference = []
                this.reset()
            }
        },
        'table.search': function () {
            this.getItems()
        },
        'table.page': function () {
            this.getItems()
        }
    },
    computed: {
        isUpdate(){
            return this.itemm.item_id
        }
    }
}
</script>

<style>

</style>